import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import Grow from '@mui/material/Grow';
import Slide from '@mui/material/Slide';
import DemoFormWithText from './DemoFormWithText';
import DemoHeroSection from './DemoHeroSection';

const DemoCard = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="bg-[#090A0F] flex items-center justify-center mt-8 md:mt-12 lg:mt-16 overflow-hidden">
            <div className="cursor-default flex flex-col items-center border-gray-700 bg-[#090A0F] w-full max-w-7xl px-4 sm:px-6 lg:px-8">
                {/* Hero Section with enhanced animation */}
                <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={1000}>
                    <div className="w-full">
                        <DemoHeroSection />
                    </div>
                </Grow>
                
                {/* Form Section with enhanced animation */}
                <Slide direction="up" in={true} mountOnEnter timeout={{ enter: 1200 }}>
                    <div className="w-full mx-auto pb-20">
                        <DemoFormWithText />
                    </div>
                </Slide>
                
                {/* Background elements for visual interest */}
                <div className="absolute top-40 right-10 w-96 h-96 bg-indigo-500/5 rounded-full blur-3xl -z-10"></div>
                <div className="absolute bottom-40 left-10 w-96 h-96 bg-indigo-500/5 rounded-full blur-3xl -z-10"></div>
            </div>
        </div>
    );
};

export default DemoCard;
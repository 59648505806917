import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { TextField, Button, MenuItem, FormControl, FormControlLabel, Checkbox, Typography, CircularProgress, Snackbar, Alert } from '@mui/material';

const DemoForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    company: '',
    industry: '',
    message: '',
    consent: false
  });
  
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertType, setAlertType] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');
  
  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setFormData({
      ...formData,
      [name]: name === 'consent' ? checked : value
    });
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    const formDataToSend = new FormData(e.target);
    
    try {
      const response = await fetch('https://formspree.io/f/mwkgpjne', {
        method: 'POST',
        body: formDataToSend,
        headers: {
          'Accept': 'application/json',
        },
      });
      
      setLoading(false);
      
      if (response.ok) {
        setAlertMessage('Tack för din förfrågan! Vi hör av oss snart för att schemalägga en tid för din demonstration!');
        setAlertType('success');
        setOpenSnackbar(true);
        setSubmitted(true);
        
        // Reset form values
        setFormData({
          name: '',
          email: '',
          phone: '',
          company: '',
          industry: '',
          message: '',
          consent: false
        });
      } else {
        setAlertMessage('Ett fel inträffade. Försök igen.');
        setAlertType('error');
        setOpenSnackbar(true);
      }
    } catch (error) {
      setLoading(false);
      setAlertMessage('Ett fel inträffade. Försök igen.');
      setAlertType('error');
      setOpenSnackbar(true);
    }
  };
  
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };
  
  const handleReset = () => {
    setSubmitted(false);
  };
  
  const industries = [
    'Fastighetsförvaltning',
    'Hotell & Restaurang',
    'Handel & Detaljhandel',
    'Kontor & Administration',
    'Skola & Utbildning',
    'Vård & Omsorg',
    'Industri & Tillverkning',
    'Övrigt'
  ];
  
  // Animation variants
  const formVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        staggerChildren: 0.1,
        delayChildren: 0.2
      }
    }
  };
  
  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
  };
  
  // Common styles for all text fields
  const textFieldStyles = {
    '& .MuiOutlinedInput-root': {
      '& fieldset': { borderColor: 'rgba(107, 114, 128, 0.5)' },
      '&:hover fieldset': { borderColor: 'rgb(99, 102, 241)' },
      '&.Mui-focused fieldset': { borderColor: 'rgb(99, 102, 241)' },
    },
    '& .MuiInputLabel-root': { color: 'rgb(156, 163, 175)' },
    '& .MuiInputBase-input': { color: 'white' },
    '& input:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px rgba(17, 24, 39, 0.8) inset',
      WebkitTextFillColor: 'white',
      caretColor: 'white',
    },
    '& .MuiSelect-select': { color: 'white' },
    '& .MuiMenuItem-root': { color: 'black' } // Set menu items to black
  };
  
  return (
    <div className="bg-gray-900/50 rounded-xl p-6 lg:p-8 backdrop-blur-sm border border-gray-800/50 shadow-lg">
      {!submitted ? (
        <motion.form 
          onSubmit={handleSubmit}
          variants={formVariants}
          initial="hidden"
          animate="visible"
          className="space-y-4"
        >
          <Typography variant="h5" component="h2" className="text-white mb-4 font-bold">
            Boka en demonstration
          </Typography>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <motion.div variants={itemVariants}>
              <TextField
                fullWidth
                required
                id="name"
                name="name"
                label="Namn"
                value={formData.name}
                onChange={handleChange}
                variant="outlined"
                className="bg-gray-800/50 rounded"
                sx={textFieldStyles}
              />
            </motion.div>
            
            <motion.div variants={itemVariants}>
              <TextField
                fullWidth
                required
                id="epost"
                name="email"
                label="E-post"
                type="email"
                value={formData.email}
                onChange={handleChange}
                variant="outlined"
                className="bg-gray-800/50 rounded"
                sx={textFieldStyles}
              />
            </motion.div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <motion.div variants={itemVariants}>
              <TextField
                fullWidth
                id="telefonnummer"
                name="phone"
                label="Telefonnummer"
                value={formData.phone}
                onChange={handleChange}
                variant="outlined"
                className="bg-gray-800/50 rounded"
                sx={textFieldStyles}
              />
            </motion.div>
            
            <motion.div variants={itemVariants}>
              <TextField
                fullWidth
                required
                id="foretag"
                name="company"
                label="Företagsnamn"
                value={formData.company}
                onChange={handleChange}
                variant="outlined"
                className="bg-gray-800/50 rounded"
                sx={textFieldStyles}
              />
            </motion.div>
          </div>
          
          <motion.div variants={itemVariants}>
            <TextField
              fullWidth
              select
              id="bransch"
              name="industry"
              label="Bransch"
              value={formData.industry}
              onChange={handleChange}
              variant="outlined"
              className="bg-gray-800/50 rounded"
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    sx: {
                      bgcolor: 'rgb(31, 41, 55)',
                      '& .MuiMenuItem-root': {
                        color: 'white',
                      },
                      '& .MuiMenuItem-root:hover': {
                        bgcolor: 'rgba(99, 102, 241, 0.2)',
                      },
                      '& .MuiMenuItem-root.Mui-selected': {
                        bgcolor: 'rgba(99, 102, 241, 0.3)',
                      },
                    },
                  },
                },
              }}
              sx={textFieldStyles}
            >
              <MenuItem value="">Välj bransch</MenuItem>
              {industries.map((industry) => (
                <MenuItem key={industry} value={industry}>
                  {industry}
                </MenuItem>
              ))}
            </TextField>
          </motion.div>
          
          <motion.div variants={itemVariants}>
            <TextField
              fullWidth
              multiline
              rows={4}
              id="meddelande"
              name="message"
              label="Meddelande (valfritt)"
              value={formData.message}
              onChange={handleChange}
              variant="outlined"
              className="bg-gray-800/50 rounded"
              sx={textFieldStyles}
            />
          </motion.div>
          
          <motion.div variants={itemVariants}>
            <FormControlLabel
              control={
                <Checkbox
                  required
                  name="consent"
                  checked={formData.consent}
                  onChange={handleChange}
                  sx={{
                    color: 'rgb(156, 163, 175)',
                    '&.Mui-checked': {
                      color: 'rgb(99, 102, 241)',
                    },
                    marginRight: 1
                  }}
                />
              }
              label={
                <Typography variant="body2" component="span" style={{ color: 'rgb(156, 163, 175)', marginLeft: '-4px' }}>
                  Jag godkänner att Guardia kontaktar mig gällande min bokningsförfrågan
                </Typography>
              }
              sx={{
                alignItems: 'flex-start',
                margin: 0,
                '& .MuiFormControlLabel-label': {
                  paddingTop: '9px'
                }
              }}
            />
          </motion.div>
          
          <motion.div 
            variants={itemVariants}
            className="pt-2"
          >
            <Button
              type="submit"
              variant="contained"
              disabled={loading}
              className="w-full bg-gradient-to-r from-indigo-500 to-indigo-700 text-white py-3 rounded-md hover:from-indigo-600 hover:to-indigo-800"
              sx={{
                textTransform: 'none',
                fontSize: '1rem',
                fontWeight: 'bold',
                boxShadow: '0 4px 6px rgba(99, 102, 241, 0.25)',
                '&:hover': {
                  boxShadow: '0 6px 8px rgba(99, 102, 241, 0.3)',
                }
              }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Boka demonstration'
              )}
            </Button>
          </motion.div>
        </motion.form>
      ) : (
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          className="text-center py-8"
        >
          <div className="mx-auto w-16 h-16 bg-indigo-100 rounded-full flex items-center justify-center mb-4">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-indigo-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
            </svg>
          </div>
          <h3 className="text-2xl font-bold text-white mb-2">Tack för din förfrågan!</h3>
          <p className="text-gray-400 mb-6">Vi kommer att kontakta dig inom kort för att boka en demonstration.</p>
          <Button
            onClick={handleReset}
            variant="outlined"
            className="border-indigo-500 text-indigo-300 hover:bg-indigo-900/20"
          >
            Skicka en ny förfrågan
          </Button>
        </motion.div>
      )}
      
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={alertType} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default DemoForm;
import React from 'react';
import { motion } from 'framer-motion';
import DemoForm from './DemoForm';

const DemoFormWithText = () => {
    return (
        <div className="flex mt-10 mb-20 flex-col items-center rounded-2xl bg-gradient-to-b from-gray-900/60 to-[#090A0F] shadow-2xl w-full md:max-w-full border border-gray-800/40 backdrop-blur-sm">
            <div className="flex flex-col md:flex-row py-6 px-4 md:p-8 lg:p-12 gap-x-12 gap-y-8">
                {/* Form area with subtle animation */}
                <motion.div 
                    className="w-full md:w-1/2"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.8, delay: 0.4 }}
                >
                    <DemoForm />
                </motion.div>
                
                {/* Text area with staggered animation */}
                <motion.div 
                    className="flex flex-col justify-start leading-normal w-full md:w-1/2"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.8, delay: 0.6 }}
                >
                    <motion.h5 
                        className="text-3xl md:text-4xl lg:text-5xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-indigo-200 to-indigo-500 mb-6"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8, delay: 0.8 }}
                    >
                        Ta första steget mot en enklare vardag med Guardia!
                    </motion.h5>

                    <motion.p 
                        className="mb-6 text-lg text-gray-400"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8, delay: 1 }}
                    >
                        Upplev den smidighet och effektivitet vi erbjuder. Ingen bindningstid, vid priser som börjar på endast 495 kr/mån.
                    </motion.p>
                    
                    <motion.div
                        className="flex flex-col gap-4"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8, delay: 1.2 }}
                    >
                        <div className="flex items-center">
                            <div className="h-10 w-10 rounded-full bg-indigo-500/20 flex items-center justify-center text-indigo-300 mr-4">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                </svg>
                            </div>
                            <p className="text-gray-300">Personlig demonstration som passar dina behov</p>
                        </div>
                        
                        <div className="flex items-center">
                            <div className="h-10 w-10 rounded-full bg-indigo-500/20 flex items-center justify-center text-indigo-300 mr-4">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                </svg>
                            </div>
                            <p className="text-gray-300">Flexibla lösningar för alla typer av verksamheter</p>
                        </div>
                        
                        <div className="flex items-center">
                            <div className="h-10 w-10 rounded-full bg-indigo-500/20 flex items-center justify-center text-indigo-300 mr-4">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                </svg>
                            </div>
                            <p className="text-gray-300">Snabb installation och support</p>
                        </div>
                    </motion.div>
                    
                    <motion.p
                        className="mt-8 text-indigo-300 font-medium"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.8, delay: 1.4 }}
                    >
                        Börja din resa mot enklare hantering redan idag!
                    </motion.p>
                </motion.div>
            </div>
        </div>
    );
};

export default DemoFormWithText;
import React, { useState, useEffect } from 'react';
import Grow from '@mui/material/Grow';

const ServicePurchaseFlow = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    // Step 1: Package Selection
    package: '',
    
    // Step 2: Payment Information
    cardName: '',
    cardNumber: '',
    expiryDate: '',
    cvc: '',
    
    // Step 3: Organization Setup
    organizationName: '',
    securityObjectName: '',
    adminUsername: '',
    adminPassword: '',
    confirmPassword: '',
  });
  
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setError('');
  };

  const handlePackageSelect = (packageType) => {
    setFormData(prev => ({
      ...prev,
      package: packageType
    }));
  };

  const nextStep = () => {
    if (currentStep === 1 && !formData.package) {
      setError('Vänligen välj ett paket för att fortsätta');
      return;
    }
    
    if (currentStep === 2) {
      // Validate payment information
      if (!formData.cardName || !formData.cardNumber || !formData.expiryDate || !formData.cvc) {
        setError('Vänligen fyll i alla betalningsuppgifter');
        return;
      }
    }
    
    setCurrentStep(prev => prev + 1);
    setError('');
  };

  const prevStep = () => {
    setCurrentStep(prev => prev - 1);
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    
    // Validate final step
    if (formData.adminPassword !== formData.confirmPassword) {
      setError('Lösenorden matchar inte');
      setIsLoading(false);
      return;
    }
    
    if (!formData.organizationName || !formData.securityObjectName || 
        !formData.adminUsername || !formData.adminPassword) {
      setError('Vänligen fyll i alla obligatoriska fält');
      setIsLoading(false);
      return;
    }

    try {
      // Submit data to API
      console.log('Submitting data:', formData);
      
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 2000));
      
      // Redirect to success page or dashboard
      window.location.href = '/';
    } catch (err) {
      setError('Ett fel uppstod. Vänligen försök igen.');
    } finally {
      setIsLoading(false);
    }
  };

  // Render step indicators
  const renderStepIndicators = () => {
    return (
      <div className="flex items-center justify-center mb-8">
        {[1, 2, 3].map((step) => (
          <React.Fragment key={step}>
            <div 
              className={`flex items-center justify-center w-10 h-10 rounded-full border-2 
                ${currentStep === step 
                  ? 'border-indigo-500 bg-indigo-500 text-white' 
                  : currentStep > step 
                    ? 'border-indigo-500 bg-indigo-500 text-white'
                    : 'border-gray-600 bg-gray-800 text-gray-400'}`}
            >
              {currentStep > step ? (
                // Checkmark for completed steps
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
              ) : (
                // Step number
                <span>{step}</span>
              )}
            </div>
            
            {step < 3 && (
              <div 
                className={`w-20 h-1 mx-1 ${currentStep > step ? 'bg-indigo-500' : 'bg-gray-700'}`}
              ></div>
            )}
          </React.Fragment>
        ))}
      </div>
    );
  };

  // Step 1: Package Selection
  const renderStep1 = () => {
    return (
      <div className="space-y-6">
        <h3 className="text-xl font-medium text-gray-200 text-center">Välj tjänstepaket</h3>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Standard Package */}
          <div 
            className={`border rounded-xl p-6 cursor-pointer transition-all duration-200 hover:shadow-lg ${
              formData.package === 'standard' 
                ? 'border-indigo-500 bg-indigo-900/20' 
                : 'border-gray-700 bg-gray-800/30 hover:border-gray-500'
            }`}
            onClick={() => handlePackageSelect('standard')}
          >
            <div className="flex justify-between items-start">
              <h4 className="text-lg font-medium text-white">Standard</h4>
              <div className="bg-indigo-600/30 text-indigo-300 px-3 py-1 rounded-full text-sm">
                Populärt
              </div>
            </div>
            <p className="mt-2 text-2xl font-bold text-white">495 kr<span className="text-sm font-normal text-gray-400">/månad</span></p>
            <p className="mt-1 text-sm text-gray-400">Baspaketet för säker bevakning</p>
            
            <ul className="mt-4 space-y-2">
              <li className="flex items-center text-sm text-gray-300">
                <svg className="h-5 w-5 mr-2 text-indigo-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                250 Artiklar
              </li>
              <li className="flex items-center text-sm text-gray-300">
                <svg className="h-5 w-5 mr-2 text-indigo-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                3 Användare
              </li>
              <li className="flex items-center text-sm text-gray-300">
                <svg className="h-5 w-5 mr-2 text-indigo-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                Fysisk Onboarding
              </li>
              <li className="flex items-center text-sm text-gray-300">
                <svg className="h-5 w-5 mr-2 text-indigo-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                Grundläggande analys
              </li>
            </ul>
          </div>
          
          {/* Premium Package */}
          <div 
            className={`border rounded-xl p-6 cursor-pointer transition-all duration-200 hover:shadow-lg ${
              formData.package === 'premium' 
                ? 'border-indigo-500 bg-indigo-900/20' 
                : 'border-gray-700 bg-gray-800/30 hover:border-gray-500'
            }`}
            onClick={() => handlePackageSelect('premium')}
          >
            <div className="flex justify-between items-start">
              <h4 className="text-lg font-medium text-white">Premium</h4>
              <div className="bg-purple-600/30 text-purple-300 px-3 py-1 rounded-full text-sm">
                Obegränsat
              </div>
            </div>
            <p className="mt-2 text-2xl font-bold text-white">995 kr<span className="text-sm font-normal text-gray-400">/månad</span></p>
            <p className="mt-1 text-sm text-gray-400">Komplett säkerhetslösning för företag</p>
            
            <ul className="mt-4 space-y-2">
              <li className="flex items-center text-sm text-gray-300">
                <svg className="h-5 w-5 mr-2 text-purple-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                Obegränsat antal objekt
              </li>
              <li className="flex items-center text-sm text-gray-300">
                <svg className="h-5 w-5 mr-2 text-purple-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                Obegränsade Artiklar
              </li>
              <li className="flex items-center text-sm text-gray-300">
                <svg className="h-5 w-5 mr-2 text-purple-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                Avancerad analys
              </li>
              <li className="flex items-center text-sm text-gray-300">
                <svg className="h-5 w-5 mr-2 text-purple-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                24×7 Telefon & Email support
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  // Step 2: Payment Information
  const renderStep2 = () => {
    return (
      <div className="space-y-6">
        <h3 className="text-xl font-medium text-gray-200 text-center">Betalningsinformation</h3>
        
        <div className="space-y-4">
          <div>
            <label htmlFor="cardName" className="block text-sm font-medium text-gray-300 mb-1">
              Namn på kort
            </label>
            <input
              id="cardName"
              name="cardName"
              type="text"
              required
              className="appearance-none rounded-lg relative block w-full px-3 py-3 border border-gray-700 bg-[#161927] placeholder-gray-500 text-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm transition-all duration-200"
              placeholder="Ange namn på kort"
              value={formData.cardName}
              onChange={handleChange}
            />
          </div>
          
          <div>
            <label htmlFor="cardNumber" className="block text-sm font-medium text-gray-300 mb-1">
              Kortnummer
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
                </svg>
              </div>
              <input
                id="cardNumber"
                name="cardNumber"
                type="text"
                required
                className="appearance-none rounded-lg relative block w-full pl-10 px-3 py-3 border border-gray-700 bg-[#161927] placeholder-gray-500 text-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm transition-all duration-200"
                placeholder="XXXX XXXX XXXX XXXX"
                value={formData.cardNumber}
                onChange={handleChange}
              />
            </div>
          </div>
          
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="expiryDate" className="block text-sm font-medium text-gray-300 mb-1">
                Utgångsdatum
              </label>
              <input
                id="expiryDate"
                name="expiryDate"
                type="text"
                required
                className="appearance-none rounded-lg relative block w-full px-3 py-3 border border-gray-700 bg-[#161927] placeholder-gray-500 text-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm transition-all duration-200"
                placeholder="MM/ÅÅ"
                value={formData.expiryDate}
                onChange={handleChange}
              />
            </div>
            
            <div>
              <label htmlFor="cvc" className="block text-sm font-medium text-gray-300 mb-1">
                CVC/CVV
              </label>
              <input
                id="cvc"
                name="cvc"
                type="text"
                required
                className="appearance-none rounded-lg relative block w-full px-3 py-3 border border-gray-700 bg-[#161927] placeholder-gray-500 text-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm transition-all duration-200"
                placeholder="123"
                value={formData.cvc}
                onChange={handleChange}
              />
            </div>
          </div>
          
          <div className="pt-4">
            <div className="flex items-center bg-indigo-900/20 border border-indigo-500/30 rounded-lg p-4">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-indigo-400 mr-2 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <p className="text-sm text-indigo-200">
                Din betalningsinformation är säker. Vi använder SSL-kryptering för att skydda dina uppgifter.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Step 3: Organization Setup
  const renderStep3 = () => {
    return (
      <div className="space-y-6">
        <h3 className="text-xl font-medium text-gray-200 text-center">Konfigurera din organisation</h3>
        
        <div className="space-y-4">
          <div>
            <label htmlFor="organizationName" className="block text-sm font-medium text-gray-300 mb-1">
              Organisationsnamn
            </label>
            <input
              id="organizationName"
              name="organizationName"
              type="text"
              required
              className="appearance-none rounded-lg relative block w-full px-3 py-3 border border-gray-700 bg-[#161927] placeholder-gray-500 text-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm transition-all duration-200"
              placeholder="Företagets namn"
              value={formData.organizationName}
              onChange={handleChange}
            />
          </div>
          
          <div>
            <label htmlFor="securityObjectName" className="block text-sm font-medium text-gray-300 mb-1">
              Namn på arbetsplats
            </label>
            <input
              id="securityObjectName"
              name="securityObjectName"
              type="text"
              required
              className="appearance-none rounded-lg relative block w-full px-3 py-3 border border-gray-700 bg-[#161927] placeholder-gray-500 text-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm transition-all duration-200"
              placeholder="t.ex. Bibliotek Hötorget"
              value={formData.securityObjectName}
              onChange={handleChange}
            />
          </div>
          
          <div>
            <label htmlFor="adminUsername" className="block text-sm font-medium text-gray-300 mb-1">
              Administratörens användarnamn
            </label>
            <input
              id="adminUsername"
              name="adminUsername"
              type="text"
              required
              className="appearance-none rounded-lg relative block w-full px-3 py-3 border border-gray-700 bg-[#161927] placeholder-gray-500 text-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm transition-all duration-200"
              placeholder="admin@företag.se"
              value={formData.adminUsername}
              onChange={handleChange}
            />
          </div>
          
          <div>
            <label htmlFor="adminPassword" className="block text-sm font-medium text-gray-300 mb-1">
              Administratörens lösenord
            </label>
            <input
              id="adminPassword"
              name="adminPassword"
              type="password"
              required
              className="appearance-none rounded-lg relative block w-full px-3 py-3 border border-gray-700 bg-[#161927] placeholder-gray-500 text-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm transition-all duration-200"
              placeholder="Skapa ett starkt lösenord"
              value={formData.adminPassword}
              onChange={handleChange}
            />
          </div>
          
          <div>
            <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-300 mb-1">
              Bekräfta lösenord
            </label>
            <input
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              required
              className="appearance-none rounded-lg relative block w-full px-3 py-3 border border-gray-700 bg-[#161927] placeholder-gray-500 text-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm transition-all duration-200"
              placeholder="Upprepa lösenordet"
              value={formData.confirmPassword}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    );
  };

  // Navigation buttons
  const renderNavButtons = () => {
    return (
      <div className="flex justify-between mt-8">
        {currentStep > 1 ? (
          <button
            type="button"
            onClick={prevStep}
            className="px-5 py-2 border border-gray-600 text-gray-300 rounded-lg hover:bg-gray-800 transition-colors duration-200"
          >
            Tillbaka
          </button>
        ) : (
          <div></div>
        )}
        
        {currentStep < 3 ? (
          <button
            type="button"
            onClick={nextStep}
            className="px-5 py-2 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-lg hover:from-indigo-700 hover:to-purple-700 transition-all duration-200 hover:shadow-lg"
          >
            Nästa
          </button>
        ) : (
          <button
            type="submit"
            disabled={isLoading}
            className="px-5 py-2 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-lg hover:from-indigo-700 hover:to-purple-700 transition-all duration-200 hover:shadow-lg disabled:opacity-50 disabled:cursor-not-allowed flex items-center"
            onClick={handleSubmit}
          >
            {isLoading ? (
              <>
                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Bearbetar...
              </>
            ) : (
              'Slutför'
            )}
          </button>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col min-h-screen pt-4 bg-gradient-to-b from-[#090A0F] to-[#111827]">
      <div className="flex-grow flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={800}>
          <div className="max-w-3xl w-full space-y-8">
            <div className="text-center">
              <h2 className="text-4xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-indigo-300 to-purple-400">
                Börja använda Guardia idag
              </h2>
              <p className="mt-3 text-gray-400 text-lg max-w-xl mx-auto">
                Konfigurera ditt Guardia-konto och stärk säkerheten på din arbetsplats inom några minuter
              </p>
            </div>
            
            <div className="bg-[#0F1118] p-8 rounded-xl border border-gray-800 shadow-2xl backdrop-blur-sm">
              {renderStepIndicators()}
              
              {error && (
                <div className="bg-red-900/30 border border-red-500/50 text-red-200 px-4 py-3 rounded-lg relative mb-6" role="alert">
                  <span className="block sm:inline">{error}</span>
                </div>
              )}
              
              {currentStep === 1 && renderStep1()}
              {currentStep === 2 && renderStep2()}
              {currentStep === 3 && renderStep3()}
              
              {renderNavButtons()}
            </div>
            
            <div className="flex items-center justify-center space-x-4 mt-4">
              <div className="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-green-400 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                </svg>
                <span className="text-sm text-gray-400">Säker betalning</span>
              </div>
              <div className="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-green-400 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
                <span className="text-sm text-gray-400">14 dagars prövoperiod</span>
              </div>
              <div className="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-green-400 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
                </svg>
                <span className="text-sm text-gray-400">Ingen bindningstid</span>
              </div>
            </div>
          </div>
        </Grow>
      </div>
    </div>
  );
};

export default ServicePurchaseFlow;
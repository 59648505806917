import React, { useRef } from "react";
import shieldImg from "../../assets/images/ShieldHighRes.png";
import OrderWindow from "../../assets/images/OrderWindow.png";
import { Container } from "@mui/material";
import Button from "@mui/material/Button";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";

import Footer from "../Misc/Footer";

const IntroToGuardia = () => {
  const targetDate = new Date("2024-03-29T17:35:10");
  
  // Create refs for scrolling
  const productShowcaseRef = useRef(null);
  
  // Function to scroll to product showcase section
  const scrollToShowcase = () => {
    // Scroll to the section
    productShowcaseRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  
  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 0 }}
        animate={{ opacity: 1, y: 10 }}
        transition={{ type: "spring", duration: 1.5, delay: 1.5 }}
        viewport={{ once: true }}
        className="flex justify-center items-center mt-10 mb-5"
      >
        {/* Countdown timer code commented out in original */}
      </motion.div>
      
      <Container className="mt-10 relative rounded-xl pb-40">
        <div className="flex flex-col lg:flex-row min-h-[50vh] relative">
          {/* Content Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ type: "spring", duration: 1.5, delay: 0.5 }}
            viewport={{ once: true, amount: 0.3 }}
            className="text-white w-full lg:w-4/5 z-10"
          >
            <h1 className="text-4xl md:text-5xl font-poppins-medium pb-6">
              Full kontroll på
              <span className="bg-gradient-to-r from-green-500 to-green-700 bg-clip-text text-transparent">
                {" "}
                nycklar och värdesaker
              </span>{" "}
              – enkelt och säkert
            </h1>
            
            <p className="text-gray-300 font-poppins-light text-lg pb-6">
              Har ni svårt att hålla reda på nycklar, datorer eller andra viktiga tillgångar? Med
              <strong className="text-white"> Guardia</strong> får ni en digital lösning för smidig in- och utlåning. Slipp manuella listor
              och osäker hantering – vår app och webbtjänst ger er full kontroll i realtid.
            </p>
            
            {/* Feature Cards instead of bullet points */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
              <div className="bg-gradient-to-br from-gray-800 to-gray-900 p-4 rounded-lg border border-gray-700 shadow-lg transform hover:scale-105 transition-all duration-200">
                <div className="text-green-500 text-2xl mb-2">✓</div>
                <h3 className="font-semibold text-white mb-1">Enkel hantering</h3>
                <p className="text-gray-400 text-sm">Smidig registrering av lån och återlämning</p>
              </div>
              <div className="bg-gradient-to-br from-gray-800 to-gray-900 p-4 rounded-lg border border-gray-700 shadow-lg transform hover:scale-105 transition-all duration-200">
                <div className="text-green-500 text-2xl mb-2">✓</div>
                <h3 className="font-semibold text-white mb-1">Realtidsöversikt</h3>
                <p className="text-gray-400 text-sm">Spåra vem som har vad – alltid uppdaterat</p>
              </div>
              <div className="bg-gradient-to-br from-gray-800 to-gray-900 p-4 rounded-lg border border-gray-700 shadow-lg transform hover:scale-105 transition-all duration-200">
                <div className="text-green-500 text-2xl mb-2">✓</div>
                <h3 className="font-semibold text-white mb-1">GDPR-kompatibel</h3>
                <p className="text-gray-400 text-sm">Uppfyll lagkrav automatiskt</p>
              </div>
            </div>
            
            {/* Tagline */}
            <p className="text-xl font-bold text-white mb-8">
              <strong>Det ska vara <span className="text-green-500">enkelt</span> att göra rätt.</strong>
            </p>
            
            {/* CTA Buttons - Simple and clean */}
            <div className="flex flex-wrap gap-4 mb-8 mt-6 relative z-20">
              <NavLink to="/demo">
                <Button
                  variant="contained"
                  className="bg-gradient-to-r from-green-600 to-green-800 hover:shadow-lg hover:shadow-green-600/20 px-6 py-2 rounded-md"
                >
                  Boka Demo
                </Button>
              </NavLink>
              <NavLink>
                <Button
                  variant="contained"
                  className="bg-gradient-to-r from-violet-500 to-violet-800 hover:shadow-lg hover:shadow-violet-600/20 px-6 py-2 rounded-md"
                  onClick={scrollToShowcase}
                >
                  Läs mer
                </Button>
              </NavLink>
            </div>
          </motion.div>

          {/* Image */}
          <motion.div
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ type: "spring", duration: 1.5, delay: 0.7 }}
            className="lg:absolute -right-20 -top-5 z-0 pointer-events-none"
            viewport={{ once: true, amount: 0.3 }}
          >
            <div className="relative hidden lg:block">
              <div className="absolute inset-0 bg-gradient-to-r from-green-500/30 to-violet-500/30 rounded-full blur-xl opacity-70 transform scale-125"></div>
              <img
                src={shieldImg}
                alt="Guardia Security"
                className="relative h-72 w-auto object-contain"
              />
            </div>
          </motion.div>
        </div>
        
        {/* Background Elements */}
        <div className="absolute top-0 right-0 -z-10 w-96 h-96 bg-green-500/5 rounded-full blur-3xl"></div>
        <div className="absolute bottom-0 left-0 -z-10 w-96 h-96 bg-violet-500/5 rounded-full blur-3xl"></div>
      </Container>

      {/* Added Product Showcase Section with BenefitsCard */}
      <Container ref={productShowcaseRef} className="mt-20 mb-20 pt-11 scroll-mt-10">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ type: "spring", duration: 1, delay: 0.3 }}
          viewport={{ once: true, amount: 0.3 }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
            <span className="bg-gradient-to-r from-green-500 to-green-700 bg-clip-text text-transparent">
              Guardia
            </span> i praktiken
          </h2>
          <p className="text-gray-300 text-lg max-w-3xl mx-auto">
            Se hur vår lösning transformerar er hantering av nycklar och värdesaker
          </p>
        </motion.div>

  
        <div className="flex flex-col md:flex-row bg-gradient-to-br from-gray-900 to-gray-800 rounded-xl shadow-2xl overflow-hidden border border-gray-700">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ type: "spring", duration: 1.5, delay: 0.5 }}
            className="w-full md:w-1/2 p-6"
            viewport={{ once: true, amount: 0.3 }}
          >
            <h3 className="text-2xl md:text-3xl font-bold text-white mb-4">
              Effektiv tids- och kostnadsbesparing med digital nyckelhantering
            </h3>
            <p className="text-gray-300 text-lg mb-6">
              Vi strävar efter att tillhandahålla en användarvänlig och säker
              tjänst med Guardia, där du och dina kollegor har full kontroll över
              era artiklar, oavsett tidpunkt eller plats.
            </p>
            <ul className="space-y-3 text-gray-300">
              <li className="flex items-center">
                <span className="text-green-500 mr-2">✓</span> Realtidsuppdatering av status
              </li>
              <li className="flex items-center">
                <span className="text-green-500 mr-2">✓</span> Sparar tid vid in- och utlämning
              </li>
              <li className="flex items-center">
                <span className="text-green-500 mr-2">✓</span> Minskar risken för förluster
              </li>
            </ul>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ type: "spring", duration: 1.5, delay: 0.8 }}
            className="w-full md:w-full p-4 flex items-center justify-center"
            viewport={{ once: true, amount: 0.3 }}
          >
            <img
              src={OrderWindow}
              alt="Guardia Order Window"
              className="rounded-lg shadow-xl max-w-full h-auto"
            />
          </motion.div>
        </div>


        <div className="mt-20">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ type: "spring", duration: 1, delay: 0.2 }}
            viewport={{ once: true, amount: 0.3 }}
            className="text-center mb-10"
          >
            <h3 className="text-2xl md:text-3xl font-bold text-white mb-2">
              Hör från våra kunder
            </h3>
            <p className="text-gray-400">Se vad andra säger om Guardia</p>
          </motion.div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* Testimonial 1 */}
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ type: "spring", duration: 1, delay: 0.4 }}
              viewport={{ once: true, amount: 0.2 }}
              className="bg-gradient-to-br from-gray-800 to-gray-900 p-6 rounded-lg border border-gray-700 shadow-lg"
            >
              <div className="flex items-center mb-4">
                <div className="h-10 w-10 rounded-full bg-green-500 flex items-center justify-center text-white font-bold">G</div>
                <div className="ml-4">
                  <h4 className="text-white font-semibold">Ghazi Faisal</h4>
                  <p className="text-gray-400 text-sm">Ägare TopGF car rental Dubai</p>
                </div>
              </div>
              <p className="text-gray-300">
              Jag rekommenderar Guardia. Allt går mycket snabbare, vi registrerar ordern och kunden signerar direkt via mail. Tidigare letade vi nycklar manuellt, skrev ner vem som hade vad och förde journaler. Nu ser vi direkt var alla bilnycklar finns och vem som har dem.
              </p>
              <div className="mt-4 flex text-yellow-400">
                <span>★</span><span>★</span><span>★</span><span>★</span><span>★</span>
              </div>
            </motion.div>
            
            {/* Testimonial 2 */}
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ type: "spring", duration: 1, delay: 0.6 }}
              viewport={{ once: true, amount: 0.2 }}
              className="bg-gradient-to-br from-gray-800 to-gray-900 p-6 rounded-lg border border-gray-700 shadow-lg"
            >
              <div className="flex items-center mb-4">
                <div className="h-10 w-10 rounded-full bg-violet-500 flex items-center justify-center text-white font-bold">M</div>
                <div className="ml-4">
                  <h4 className="text-white font-semibold"> Mustafa Kuzey</h4>
                  <p className="text-gray-400 text-sm">Delägare Telora AB</p>
                </div>
              </div>
              <p className="text-gray-300">
               Flexibelt, snabbt och nu har vi full koll på vårat inventarie
              </p>
              <div className="mt-14 flex text-yellow-400 pt-20">
                <span>★</span><span>★</span><span>★</span><span>★</span><span>★</span>
              </div>
            </motion.div>
            {/* Testimonial 3 */}
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ type: "spring", duration: 1, delay: 0.8 }}
              viewport={{ once: true, amount: 0.2 }}
              className="bg-gradient-to-br from-gray-800 to-gray-900 p-6 rounded-lg border border-gray-700 shadow-lg"
            >
              <div className="flex items-center mb-4">
                <div className="h-10 w-10 rounded-full bg-green-600 flex items-center justify-center text-white font-bold">E</div>
                <div className="ml-4">
                  <h4 className="text-white font-semibold">Elias Kaisoum</h4>
                  <p className="text-gray-400 text-sm">Ägare Ideal Clinic AB</p>
                </div>
              </div>
              <p className="text-gray-300">
                Tidigare höll vi koll på behandlingsutrustning i Excel och anteckningar. Nu registrerar vi vem som använder utrustningen, och det är klart. Största nyttan är att vi snabbt ser vilka enheter som är tillgängliga utan att behöva springa runt i kliniken.
              </p>
              <div className="mt-4 flex text-yellow-400">
                <span>★</span><span>★</span><span>★</span><span>★</span><span>★</span>
              </div>
            </motion.div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default IntroToGuardia;
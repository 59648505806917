import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import MainWindow from '../Views/MainWindow';
import DemoWindow from '../Views/DemoWindow';
import PriceWindow from '../Views/PriceWindow';
import SupportWindow from '../Views/SupportWindow';
import PrivacyPolicyWindow from '../Views/PrivacyPolicyWindow';
import DownloadWindow from '../Views/DownloadWindow';
import OrderConfirmation from '../Views/OrderConfirmation';
import MoreInfoWindow from '../Views/MoreInfoWindow';
import AdminLogin from '../Views/AdminLogin';
import AdminDashboard from '../Views/AdminDashboard';
import ProtectedRoute from '../Misc/ProtectedRoute';
import { AuthProvider } from '../../context/AuthContext';
import StartWindow from '../Views/StartWindow';
import CookieConsent from '../Misc/CookeConsent';

const Body = () => {
  return (
    <Router>
      {/* <AuthProvider> */}
        <RoutesContent />
        <CookieConsent />
      {/* </AuthProvider> */}
    </Router>
  );
};

const RoutesContent = () => {
  return (
    <Routes>
      <Route path="/" element={<MainWindow />} />
      <Route path="/moreinfo" element={<MoreInfoWindow />} />
      <Route path="/demo" element={<DemoWindow />} />
      <Route path="/priser" element={<PriceWindow />} />
      <Route path="/support" element={<SupportWindow />} />
      <Route path="/privacypolicy" element={<PrivacyPolicyWindow />} />
      <Route path="/confirmOrder" element={<OrderConfirmation />} />
      {/* <Route path="/adminlogin" element={<AdminLogin />} /> */}
      <Route path="/start" element={<StartWindow />} />
      {/* Protected Admin Routes */}
      {/* <Route
        path="/admin-dashboard"
        element={
          <ProtectedRoute>
            <AdminDashboard />
          </ProtectedRoute>
        }
      /> */}
    </Routes>
  );
};

export default Body;
import React from 'react';
import { motion } from 'framer-motion';

const DemoHeroSection = () => {
  // Animation variants
  const textVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.2,
        duration: 0.8,
        ease: "easeOut"
      }
    })
  };

  return (
    <div className="text-center py-20 px-4 relative">
      {/* Main title with staggered animation */}
      <motion.h1 
        className="mb-6 text-4xl font-bold lg:text-6xl"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        Upptäck{" "}
        <motion.span 
          className="text-indigo-500 bg-clip-text bg-gradient-to-r from-indigo-400 to-indigo-600"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 0.3, duration: 0.6 }}
        >
          Enkelhet
        </motion.span>{" "}
        och{" "}
        <motion.span 
          className="text-indigo-500 bg-clip-text bg-gradient-to-r from-indigo-400 to-indigo-600"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 0.6, duration: 0.6 }}
        >
          Effektivitet
        </motion.span>
      </motion.h1>
      
      {/* Subtitle with fade-in animation */}
      <motion.p 
        className="mx-auto max-w-2xl lg:text-xl text-gray-400 sm:text-base md:text-lg leading-relaxed"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.9, duration: 0.8 }}
      >
        Boka en demonstration av Guardia och se själv hur vårt system kan transformera din dagliga hantering av nycklar och värdefulla tillgångar. Vår lösning garanterar inte bara säkerhet utan även en mer strukturerad och effektiv arbetsmiljö.
      </motion.p>
      
      {/* Call to action text */}
      <motion.p
        className="mt-6 text-indigo-300 font-medium"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.2, duration: 0.8 }}
      >
        Vänta inte, upplev fördelarna idag!
      </motion.p>
      
      {/* Accent decorative elements */}
      <div className="absolute top-10 left-10 w-8 h-8 rounded-full bg-indigo-500/10 blur-sm"></div>
      <div className="absolute bottom-10 right-10 w-12 h-12 rounded-full bg-indigo-500/10 blur-sm"></div>
    </div>
  );
};

export default DemoHeroSection;
import React, { useState, useEffect, useCallback } from 'react';
import construction from '../../assets/images/Construction.jpg';
import fastighet from '../../assets/images/Fastighet.jpg';
import hotell from '../../assets/images/Hreception.jpg';
import securit from '../../assets/images/Securityguard.jpg';
import { motion, AnimatePresence } from 'framer-motion';

const CarouselItem = ({ image, title, description, isActive }) => (
  <motion.div 
    initial={{ opacity: 0, scale: 0.95 }}
    animate={{ opacity: isActive ? 1 : 0, scale: isActive ? 1 : 0.95 }}
    exit={{ opacity: 0, scale: 0.95 }}
    transition={{ duration: 0.5, ease: "easeInOut" }}
    className={`relative w-full ${isActive ? 'block' : 'hidden'}`}
  >
    {/* Card container with shadow and border */}
    <div className="relative overflow-hidden group rounded-xl md:rounded-2xl bg-gray-900 shadow-xl md:shadow-2xl">
      {/* Image with overlay gradient */}
      <div className="relative h-[40vh] sm:h-[50vh] md:h-[60vh] overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/80 to-transparent z-10"></div>
        <motion.img 
          src={image} 
          alt={title}
          className="w-full h-full object-cover object-center transition-transform duration-700 ease-in-out group-hover:scale-105" 
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.7 }}
        />
      </div>

      {/* Text content */}
      <div className="absolute bottom-0 left-0 right-0 p-4 sm:p-6 md:p-8 z-20">
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.5 }}
          className="w-full max-w-xl mx-auto"
        >

          {/* Title */}
          <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold mb-2 md:mb-4 text-white">
            {title}
          </h2>

          {/* Description - Truncated on small screens */}
          <p className="text-sm sm:text-base md:text-lg text-gray-300 leading-relaxed line-clamp-3 sm:line-clamp-4 md:line-clamp-none">
            {description}
          </p>
        </motion.div>
      </div>
    </div>
  </motion.div>
);

const NavButton = ({ direction, onClick }) => (
  <button
    className={`absolute top-1/2 -translate-y-1/2 z-30 flex items-center justify-center w-10 h-10 sm:w-12 sm:h-12 rounded-full bg-gray-900/70 backdrop-blur-md border border-gray-700 shadow-lg transition-transform hover:bg-gray-800 active:scale-95 ${
      direction === 'prev' ? 'left-2 sm:left-4 md:left-6' : 'right-2 sm:right-4 md:right-6'
    }`}
    onClick={onClick}
    aria-label={direction === 'prev' ? 'Previous slide' : 'Next slide'}
  >
    <svg 
      className="w-5 h-5 sm:w-6 sm:h-6 text-white" 
      fill="none" 
      stroke="currentColor" 
      viewBox="0 0 24 24" 
      xmlns="http://www.w3.org/2000/svg"
    >
      {direction === 'prev' ? (
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
      ) : (
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
      )}
    </svg>
  </button>
);

// Mobile indicator dots
const IndicatorDots = ({ images, currentIndex, goToSlide }) => (
  <div className="absolute bottom-4 left-0 right-0 z-20 flex justify-center">
    <div className="flex gap-1.5 px-3 py-1.5 bg-gray-900/50 backdrop-blur-sm rounded-full">
      {images.map((_, index) => (
        <button
          key={index}
          onClick={() => goToSlide(index)}
          className={`w-2 h-2 rounded-full transition-all duration-300 ${
            currentIndex === index ? 'bg-white scale-125' : 'bg-white/40'
          }`}
          aria-label={`Go to slide ${index + 1}`}
        />
      ))}
    </div>
  </div>
);

const Carousel = () => {
  const images = [
    {
      src: securit,
      title: "Bevakningsbranschen",
      description: "Guardia förenklar hanteringen av nycklar och säkerhetsutrustning i bevakningsbranschen, med en GDPR-kompatibel applikation som säkerställer trygg och säker lån och återlämning, så du kan fokusera på att skydda andra."
    },
    {
      src: hotell,
      title: "Hotellbranschen",
      description: "För hotellbranschen erbjuder Guardia en smidig lösning för att hantera lån av nycklar och utrustning. Förenkla din vardag, så att du kan erbjuda dina gäster en bekväm och säker vistelse."
    },
    {
      src: fastighet,
      title: "Fastighetsbranschen",
      description: "Guardia stärker fastighetsbranschen genom att effektivisera hantering av artiklar, eliminera behovet av dyra skåp och komplicerade system, och spara både tid och pengar."
    },
    {
      src: construction,
      title: "Byggbranschen",
      description: "Guardia förbättrar byggbranschen med en pålitlig, GDPR-kompatibel lösning för artikelhantering som förenklar processer och säkerställer en högkvalitativ arbetsmiljö för alla projektstorlekar."
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);

  // Handle auto-play
  useEffect(() => {
    if (!isAutoPlaying) return;
    
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    }, 8000);

    return () => clearInterval(interval);
  }, [images.length, isAutoPlaying]);

  // Pause auto-play on hover or touch
  const handlePause = () => setIsAutoPlaying(false);
  const handleResume = () => setIsAutoPlaying(true);

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  // Keyboard navigation
  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'ArrowLeft') {
        goToPrevSlide();
      } else if (event.key === 'ArrowRight') {
        goToNextSlide();
      }
    },
    []
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <div 
      className="relative mt-12 sm:mt-16 md:mt-20 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8" 
      tabIndex="0" 
      aria-label="carousel" 
      onKeyDown={handleKeyDown}
      onMouseEnter={handlePause}
      onMouseLeave={handleResume}
      onTouchStart={handlePause}
      onTouchEnd={handleResume}
    >
      {/* Main carousel area */}
      <div className="relative overflow-hidden rounded-xl md:rounded-3xl shadow-xl">
        <AnimatePresence mode="wait">
          {images.map((image, index) => (
            <CarouselItem 
              key={index} 
              image={image.src} 
              title={image.title} 
              description={image.description} 
              isActive={index === currentIndex}
            />
          ))}
        </AnimatePresence>

        {/* Navigation arrows */}
        <NavButton direction="prev" onClick={goToPrevSlide} />
        <NavButton direction="next" onClick={goToNextSlide} />

        {/* Mobile indicator dots */}
        <div className="md:hidden">
          <IndicatorDots 
            images={images} 
            currentIndex={currentIndex} 
            goToSlide={goToSlide} 
          />
        </div>
      </div>
      
      {/* Slide thumbnails - Desktop only */}
      <div className="hidden md:flex mt-4 justify-center gap-2">
        {images.map((image, index) => (
          <button
            key={index}
            onClick={() => goToSlide(index)}
            className={`relative overflow-hidden h-14 w-20 lg:h-16 lg:w-24 rounded-lg border-2 transition-all ${
              currentIndex === index ? 'border-white' : 'border-transparent opacity-60 hover:opacity-80'
            }`}
          >
            <img 
              src={image.src} 
              alt={`Thumbnail ${index + 1}`}
              className="h-full w-full object-cover"
            />
          </button>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
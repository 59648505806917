import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { motion, AnimatePresence } from 'framer-motion';

const CookieConsent = () => {
  const [showConsent, setShowConsent] = useState(false);
  
  useEffect(() => {
    const hasConsented = Cookies.get('guardia-cookie-consent');
    if (!hasConsented) {
      const timer = setTimeout(() => {
        setShowConsent(true);
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, []);
  
  const acceptCookies = () => {
    Cookies.set('guardia-cookie-consent', 'true', { expires: 30 });
    setShowConsent(false);
  };
  
  return (
    <AnimatePresence>
      {showConsent && (
        <motion.div 
          className="fixed bottom-5 left-5 right-5 md:left-auto md:right-5 md:bottom-5 md:max-w-sm bg-gray-900 p-4 rounded-lg shadow-lg border border-gray-700 z-50"
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 100, opacity: 0 }}
          transition={{ type: "spring", damping: 20 }}
        >
          <div className="flex flex-col">
            <p className="text-white text-sm mb-3">
              Vi använder cookies för att förbättra din upplevelse på vår webbplats. Genom att fortsätta godkänner du vår användning av cookies.
            </p>
            <div className="flex justify-end space-x-3">
              <button 
                onClick={acceptCookies}
                className="text-xs bg-gray-700 hover:bg-gray-600 text-white px-3 py-1 rounded transition-colors"
              >
                Avvisa
              </button>
              <button 
                onClick={acceptCookies}
                className="text-xs bg-green-600 hover:bg-green-500 text-white px-3 py-1 rounded transition-colors"
              >
                Acceptera
              </button>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CookieConsent;